import { FC } from "react";
import { Button as MuiButton, ButtonProps } from "@mui/material";

// Internal import
import "./Button.scss";

export const Button: FC<ButtonProps> = (props) => {
  const { className, variant = "contained", ...rest } = props;
  return (
    <MuiButton
      className={`button-container ${className}`}
      {...rest}
      variant={variant}
    >
      {props.children}
    </MuiButton>
  );
};
