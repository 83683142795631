import { useContext } from "react";

// Third party
import { FcDocument } from "react-icons/fc";
import { Chip } from "@mui/material";

// Internal imports
import "./TemplateTopHeader.scss";
import { GlobalContextData } from "../../../context";

export const TemplateTopHeader = () => {
  const { state } = useContext(GlobalContextData);
  return (
    <div className="template-top-header-container">
      <div className="left-template-section">
        <span>
          <FcDocument className="fc-doc" />
        </span>
        <div className="doc-with-status">
          <span className="doc-name">{state.templateInfo.name || ""}</span>
          {state.templateInfo.name !== "" && (
            <Chip
              key={"status"}
              className="chip-gap"
              size="small"
              color="primary"
              label={"Template"}
              variant="outlined"
            />
          )}
        </div>
      </div>
    </div>
  );
};
