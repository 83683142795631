import { useContext } from "react";

// Third party
import { jsPDF } from "jspdf";

//internal
import { GlobalContextData } from "../../../context";
import "./SharedTopHeader.scss";
import { Button } from "../../Button";
import moment from "moment";

export const SharedTopHeader = () => {
  const { dispatch, state } = useContext(GlobalContextData);

  const downloadDoc = () => {
    dispatch({
      type: "SET_LOADING",
      payload: { isLoading: true, loadingText: "Downloading..." },
    });
    try {
      const input = document.getElementById("editor-container");
      const temp = input?.getElementsByClassName("editor")[0] as HTMLElement;
      if (temp) {
        const styleElement = document.createElement("style");
        styleElement.innerHTML = `
    * {
      font-family: sans-serif !important;
      line-height: 160%;
    }
      h3 {
      line-height: 34px;
      }

  `;

        temp.appendChild(styleElement);
      }
      // @ts-ignore
      //const width = temp?.offsetWidth;
      // @ts-ignore
      //const height = temp?.offsetHeight;

      const doc = new jsPDF({
        orientation: "p",
        format: "a1",
        unit: "px",
      });

      doc.html(temp as HTMLElement, {
        html2canvas: {
          letterRendering: true,
          allowTaint: true,
          svgRendering: true,
          scale: 2,
          windowWidth: 1470,
        },
        async callback(doc) {
          doc.setPage(1);
          doc.setFontSize(15);
          doc.text(
            moment(state.documentInfo.publishedOn).format(
              "DD MMM YYYY HH:mm A"
            ) || "",
            doc.internal.pageSize.width - 200,
            23
          );

          doc.text(
            "Version" + " " + state.documentInfo.revisionVersion.toString() ||
              "",
            50,
            23,
            {
              align: "left",
            }
          );

          doc.setFontSize(20);

          doc.text(
            `${state.documentInfo.name || ""} - ${
              state.documentInfo.shipName || ""
            }` || "",
            doc.internal.pageSize.width / 2,
            25,
            {
              align: "center",
            }
          );

          doc.setPage(doc.internal.pages.length - 1);
          doc.text(
            "Controlled ",
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 20,
            {
              align: "center",
            }
          );

          await doc.save(`${state.documentInfo.name}.pdf`);
          dispatch({
            type: "SET_LOADING",
            payload: { isLoading: false, loadingText: "" },
          });
        },
      });
    } catch (err) {
      console.error("Download failed", err);
      dispatch({
        type: "SET_LOADING",
        payload: { isLoading: false, loadingText: "" },
      });
    }
  };
  return (
    <div className="shared-top-header-container ">
      <div className="left-shared-section"></div>
      <div className="center-shared-section">
        <img src={"/Logo.jpg"} alt=" Logo" height={60} />
        <div className="doc-with-status">
          <span className="doc-name">{state.documentInfo.name || ""}</span>
        </div>
        <Button
          className="download-button"
          size="small"
          variant="outlined"
          onClick={downloadDoc}
        >
          Download
        </Button>
      </div>
      <div className="center-shared-section-mobile">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={"/Logo.jpg"} alt=" Logo" height={60} />
          <div className="doc-with-status">
            <span className="doc-name">{state.documentInfo.name || ""}</span>
          </div>
        </div>
        <Button
          className="download-button"
          size="small"
          variant="outlined"
          onClick={downloadDoc}
        >
          Download
        </Button>
      </div>
      <div className="right-shared-section"></div>
    </div>
  );
};
