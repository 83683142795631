import jwt_decode from "jwt-decode";

/**
 * Retrieves user data from the session storage.
 *
 * @return {any} The decoded user data if a token is present in the session storage, otherwise null.
 */
export const getUserData = (): any => {
  const token = sessionStorage.getItem("token");
  if (token) {
    return jwt_decode(token);
  }
  return null;
};

/**
 * Converts an ISO date string to a custom format.
 *
 * @param {string} isoDate - The ISO date string to be formatted.
 * @return {string} The formatted date string.
 */
export const formatDateToCustomFormat = (isoDate: string): string => {
  if (!isoDate) return "";

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const formattedDate = new Date(isoDate).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

export const formatVersion = (version: number): string => {
  let [major, minor] = (version+"").split(".");

  return `${major}.${minor || 0}`;
}