//Internal imports
import { Skeleton } from "@mui/material";
import "./Card.scss";

export type ICardProps = {
  id: string;
  title: string;
  date?: string;
  count?: string;
  leftIcon?: any;
  rightIcon?: any;
  className?: string;
  onClick?: () => void;
  active?: boolean;
  isLoading?: boolean;
};

export const Card = ({
  title,
  date,
  count,
  leftIcon,
  rightIcon,
  className,
  onClick,
  active,
  isLoading,
}: ICardProps) => {
  return (
    <>
      <div
        className={`card-container ${active ? "active-card" : ""} ${className}`}
        onClick={onClick}
      >
        <div className="card-header">
          <div>{leftIcon}</div>
          <div className="right-icon">{rightIcon}</div>
        </div>
        <div className="card-title">{title}</div>
        <div className="card-footer">
          {isLoading ? (
            <Skeleton variant="rectangular" width={80} height={18} />
          ) : (
            <span>{count}</span>
          )}
          {isLoading ? (
            <Skeleton variant="rectangular" width={80} height={18} />
          ) : (
            <span>{date}</span>
          )}
        </div>
      </div>
    </>
  );
};
