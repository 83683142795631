import Modal from "@mui/material/Modal";
import { ModalsProps } from "./BaseModal.type";

// Internal imports
import "./BaseModal.scss";

export const BaseModal = ({
  open,
  onClose,
  onSubmit,
  modalTitle,
  className,
  children,
}: ModalsProps) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div className={className ? className : "modal-container"}>
            {modalTitle && (
              <>
                <div className="modal-title">
                  <h3>{modalTitle}</h3>
                </div>
                <hr />
              </>
            )}
            {children}
          </div>
        </>
      </Modal>
    </div>
  );
};
