import React, { useContext } from "react";

// Third party
import { FcDocument } from "react-icons/fc";

// Internal imports
import "./DeletedTopHeader.scss";
import { GlobalContextData } from "../../../context";
import { DisplayStatus } from "../../../components";

export const DeletedTopHeader = () => {
  const { state } = useContext(GlobalContextData);
  return (
    <div className="deleted-top-header-container">
      <div className="left-deleted-sec">
        <span>
          <FcDocument className="fc-doc" />
        </span>
        <div className="doc-with-status">
          {state.documentInfo.deleted === true && (
            <DisplayStatus status={"Deleted"} />
          )}
        </div>
      </div>
      <div className="center-deleted-sec">
        <span className="doc-name">
          {`${state.documentInfo.name || ""} - ${
            state.documentInfo.shipName || ""
          }`}
        </span>
      </div>
    </div>
  );
};
