import { Skeleton } from "@mui/material";

export const SkeltonPlaceholder = () => {
  return (
    <>
      <tr>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={100} height={18} />
        </td>
      </tr>
    </>
  );
};
