import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useLogoutTimer = (inactivityTimeoutMinutes: number) => {
  const logoutTimerRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  const handleLogOut = () => {
    if (sessionStorage.getItem("token")) {
      sessionStorage.removeItem("token");
      toast.info(
        "You have been inactive for more than 15 minutes, Please login again to continue"
      );
      navigate("/");
    }
  };

  const resetLogoutTimer = () => {
    let timer = logoutTimerRef.current;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      handleLogOut();
    }, inactivityTimeoutMinutes * 60 * 1000);

    logoutTimerRef.current = timer;
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetLogoutTimer();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactivityTimeoutMinutes]);

  return resetLogoutTimer;
};
