import React from "react";
import "./DisplayStatus.scss";

interface DisplayStatusProps {
  status: string;
}

export const DisplayStatus: React.FC<DisplayStatusProps> = ({ status }) => {
  return <div className={`${status.toLowerCase()}`}>{status}</div>;
};
