// Internal imports
import "./Header.scss";
import logo from "../../resources/images/carnival-logo.svg";

type HeaderProps = {
  showMenuSection?: boolean;
};

export const Header = ({ showMenuSection = true }: HeaderProps) => {
  return (
    <>
      <header
        aria-label="navigation bar"
        className={`header-container`}
        style={{ zIndex: 999 }}
      >
        <div className="container" style={{ zIndex: 999 }}>
          <div className="nav-start">
            <span className="logo">
              <img
                src={"/Logo.jpg"}
                alt=" Logo"
                height={64}
                width={74}
                style={{ marginTop: "1rem", marginLeft: "0.5rem" }}
              />
            </span>
          </div>
          {/* <div className="nav-end">
            {showRightSection && (
              <>
                <nav
                  className={`menu ${showMenu ? "show" : ""}`}
                  style={{ zIndex: 999 }}
                >
                  <MenuBar />
                  {isSmallScreen && (
                    <div className="sidebar-action-btn">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          navigate("/editor-view");
                          setShowMenu(false);
                        }}
                        size="large"
                        className="sidebar-button"
                      >
                        Create
                      </Button>
                    </div>
                  )}
                </nav>
                {!isSmallScreen && (
                  <div className="right-container">
                    <Button
                      variant="contained"
                      onClick={() => navigate("/editor-view")}
                      size="large"
                      className="right-button-container"
                    >
                      Create
                    </Button>
                  </div>
                )}

                <Button
                  className="hamburger"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <MenuOutlinedIcon />
                </Button>
              </>
            )}
          </div> */}
        </div>
      </header>
    </>
  );
};
