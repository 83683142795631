export const INVITE_USER = "auth/invite-user";
export const LOGIN = "auth/login";
export const LIST_ROLE = "roles";

export const API = {
  SHIP: {
    LIST: "ships",
  },
  AUTH: {
    CHANGE_PASSWORD: "auth/change-password",
    REFRESH_AUTH_TOKENS: "auth/refresh-auth-tokens",
    LOG_OUT: "auth/logout",
  },
  DOCUMENT: {
    LIST: "documents",
    SUMMARY: "document/summary",
    RECENTLY_MODIFIED: "document/recently-modified?limit=10",
    DOC_CALL: "document",
    HISTORY: "documentHistories",
    UPDATE: "document",
    SHARED_DOC: "shared-document-content",
    SHARE_DOC_CALL: "shared-document",
    UPLOAD_DOC_BASE64_IMAGE: "storage/upload-base64-image",
    REVISIONS: "document/revisions",
  },
  SUPPORT: {
    CONTACT_US: "support/contact-us",
  },
  USER: {
    LIST: "users",
    UPDATE: "user",
  },
};
export const LIST_DOCUMENTS = "documents";
export const LIST_SHIPS = "ships";
