import { useContext } from "react";

//Third party
import { Step, StepLabel, Stepper } from "@mui/material";

// Context
import { GlobalContextData } from "../../context";

// Internal imports
import "./CustomStepper.scss";

const defaultSteps = ["Create document", "Edit document", "Publish document"];

type CustomStepperProps = {
  steps?: Array<string>;
};

export const CustomStepper = ({ steps = defaultSteps }: CustomStepperProps) => {
  const { state } = useContext(GlobalContextData);

  return (
    <>
      {state.hideStepper === false && (
        <div className="stepper-wrapper">
          <Stepper activeStep={state.activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step
                key={label}
                // sx={{
                //   "& .MuiStepLabel-root .Mui-completed": {
                //     color: "#0f559a", // circle color (COMPLETED)
                //   },
                //   "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                //     {
                //       color: "#0f559a", // Just text label (COMPLETED)
                //     },
                //   "& .MuiStepLabel-root .Mui-active": {
                //     color: "#0f559a", // circle color (ACTIVE)
                //   },

                //   "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                //     {
                //       family: "Poppins",
                //     },
                //   // "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                //   //color: "common.white", // Just text label (ACTIVE)
                //   //   fill: "black", // circle's number (ACTIVE)
                //   // },
                // }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      )}
    </>
  );
};
