import { Navigate, Outlet, useLocation } from "react-router-dom";

// Internal imports
import { GlobalContextData } from "../../context";

import "./MainLayout.scss";
import { TopHeader, LeftNavBar, LoadingOverlay } from "../";
import { getUserData } from "../../shared/utils";
import { useContext } from "react";

export const MainLayout = () => {
  const { state } = useContext(GlobalContextData);
  const location = useLocation();

  const shouldHideLeftNavSection = location.pathname.includes("/shared");

  if (!getUserData()?.email && !shouldHideLeftNavSection) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <main className="main-layout-container">
        {state.loadingState.isLoading && (
          <LoadingOverlay loadingText={state.loadingState.loadingText || ""} />
        )}

        {!shouldHideLeftNavSection && <LeftNavBar />}
        <div className="home">
          <TopHeader />
          <Outlet />
        </div>
      </main>
    </>
  );
};
