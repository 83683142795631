import React from "react";

// Third party
import { MdCancel } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";

// Internal imports
import { Button } from "../Button";

// Style
import "./BlockDialogBox.scss";

type BlockDialogBoxProps = {
  docText?: any;
  onSubmit: () => void;
  onClose: () => void;
};

export const BlockDialogBox = ({
  docText,
  onClose,
  onSubmit,
}: BlockDialogBoxProps) => {
  return (
    <div className="block-box-container">
      <div className="block-box-body">
        {docText?.isUserBlocked
          ? "Are you sure you want to unblock this user? The user will regain access to the platform."
          : "Are you sure you want to block this user? Blocked users will no longer be able to access the platform."}
      </div>
      <hr />
      <div className="block-box-bottom">
        <Button className="block-box-cancel" onClick={onClose}>
          <MdCancel className="cancel-icon" />
          Cancel
        </Button>
        <Button className="block-box-submit" onClick={onSubmit}>
          <GiConfirmed className="submit-icon" />
          Confirm
        </Button>
      </div>
    </div>
  );
};
