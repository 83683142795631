import { MdCancel } from "react-icons/md";
import { Button } from "../Button";

// internal
import "./ConfirmationModal.scss";
import { GiConfirmed } from "react-icons/gi";

type ConfirmationModalProps = {
  docName?: string;
  onSubmit: () => void;
  onClose: () => void;
  id?: string;
};

export const ConfirmationModal = ({
  docName,
  onClose,
  onSubmit,
}: ConfirmationModalProps) => {
  return (
    <div className="confirmation-modal">
      <div className="help-box-body">
        Are you sure want to delete{" "}
        <span className="docName">{docName || ""}</span> ?
      </div>
      <hr />
      <div className="help-box-bottom">
        <Button className="help-box-cancel" onClick={onClose}>
          <MdCancel className="cancel-icon" />
          Cancel
        </Button>
        <Button className="help-box-submit" onClick={onSubmit}>
          <GiConfirmed className="submit-icon" />
          Confirm
        </Button>
      </div>
    </div>
  );
};
