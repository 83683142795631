import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Third party
import { jsPDF } from "jspdf";
import { FcDocument } from "react-icons/fc";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineUpdate } from "react-icons/md";
import { Chip, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Styles
import "./PublishTopHeader.scss";
import { Button } from "../../Button";

// Internal
import { GlobalContextData } from "../../../context";
import moment from "moment";
import { API, DOC_STATUS } from "../../../Constants";
import { Popper } from "../../Popper";
import { usePostService } from "../../../hooks";
import { toast } from "react-toastify";
import { DisplayStatus } from "../../../components";

type PublishTopHeaderProps = {
  onClick: () => void;
};

export const PublishTopHeader = ({ onClick }: PublishTopHeaderProps) => {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(GlobalContextData);
  const [moreDocInfo, setMoreDocInfo] = useState(false);
  const { postData } = usePostService();
  const [showMobileNav, setShowMobileNav] = useState(false);

  const downloadDoc = () => {
    dispatch({
      type: "SET_LOADING",
      payload: { isLoading: true, loadingText: "Downloading..." },
    });
    try {
      const input = document.getElementById("editor-container");
      const temp = input?.getElementsByClassName("editor")[0] as HTMLElement;
      if (temp) {
        const styleElement = document.createElement("style");
        styleElement.innerHTML = `
    * {
      font-family: sans-serif !important;
      line-height: 160%;
    }
      h3 {
      line-height: 34px;
      }

  `;

        temp.appendChild(styleElement);
      }
      // @ts-ignore
      //const width = temp?.offsetWidth;
      // @ts-ignore
      //const height = temp?.offsetHeight;

      const doc = new jsPDF({
        orientation: "p",
        format: "a1",
        unit: "px",
      });

      doc.html(temp as HTMLElement, {
        html2canvas: {
          letterRendering: true,
          allowTaint: true,
          svgRendering: true,
          scale: 1,
        },
        async callback(doc) {
          doc.setPage(1);
          // doc.setFontSize(15);
          doc.text(
            moment(state.documentInfo.publishedOn).format(
              "DD MMM YYYY HH:mm A"
            ) || "",
            doc.internal.pageSize.width - 200,
            23
          );

          // doc.text(
          //   "Version" + " " + state.documentInfo.revisionVersion.toString() ||
          //     "",
          //   50,
          //   23,
          //   {
          //     align: "left",
          //   }
          // );

          // doc.setFontSize(20);

          doc.text(
            `${state.documentInfo.name || ""} - ${
              state.documentInfo.shipName || ""
            }` || "",
            doc.internal.pageSize.width / 2,
            25,
            {
              align: "center",
            }
          );

          doc.setPage(doc.internal.pages.length - 1);
          doc.text(
            "Controlled ",
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 20,
            {
              align: "center",
            }
          );

          await doc.save(`${state.documentInfo.name}.pdf`);
          dispatch({
            type: "SET_LOADING",
            payload: { isLoading: false, loadingText: "" },
          });
        },
      });
    } catch (err) {
      console.error("Download failed", err);
      dispatch({
        type: "SET_LOADING",
        payload: { isLoading: false, loadingText: "" },
      });
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (!target.closest(".more-info-popper")) {
      setMoreDocInfo(false);
    }
  };

  const handleCreateRevision = async () => {
    dispatch({
      type: "SET_LOADING",
      payload: { isLoading: true, loadingText: "Creating new revision..." },
    });
    const newVersionDocInfo = { ...state.documentInfo };

    try {
      const response = await postData(`${API.DOCUMENT.DOC_CALL}/revision`, {
        documentId: newVersionDocInfo.id,
      });
      if (response && response?.data) {
        dispatch({
          type: "SET_LOADING",
          payload: {
            isLoading: false,
            loadingText: "Creating new revision...",
          },
        });
        navigate(`/editor-view/${response?.data?.id}`);
      }
    } catch (err) {
      console.error(err);
      toast.error(err as string);
      dispatch({
        type: "SET_LOADING",
        payload: {
          isLoading: false,
          loadingText: "Creating new revision...",
        },
      });
    }
  };

  return (
    <div className="publish-top-header-container">
      <div className="left-publish-sec">
        <div className="hamburger-menu">
          <div
            onClick={() => {
              setShowMobileNav(!showMobileNav);
            }}
          >
            {/* <RxHamburgerMenu
              style={{ width: "2.5rem", height: "2.5rem", marginRight: "2rem" }}
            /> */}
          </div>
          {/* {showMobileNav && <div className="hamburger-menu-item"></div>} */}
        </div>
        <span>
          <FcDocument className="fc-doc" />
        </span>
        <div className="doc-with-status">
          {state.documentInfo.status === DOC_STATUS.PUBLISH && (
            <DisplayStatus status={"Published"} />
          )}
        </div>
      </div>
      <div className="center-publish-sec">
        <span className="doc-name">
          {`${state.documentInfo.name || ""} - ${
            state.documentInfo.shipName || ""
          }`}
        </span>
      </div>
      <div className="right-publish-sec">
        <Button
          className="button-sec-class"
          size="small"
          onClick={handleCreateRevision}
        >
          Create Revision
        </Button>
        <Button className="button-sec-class" size="small" onClick={onClick}>
          Share
        </Button>
        <Button
          className="download-button"
          size="small"
          variant="outlined"
          onClick={downloadDoc}
        >
          Download
        </Button>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={moreDocInfo ? "long-menu" : undefined}
          aria-expanded={moreDocInfo ? "true" : undefined}
          aria-haspopup="true"
          onClick={() => setMoreDocInfo(!moreDocInfo)}
        >
          <MoreVertIcon />
        </IconButton>
        {moreDocInfo && (
          <Popper
            onClose={() => setMoreDocInfo((prevState) => !prevState)}
            handleClickOutside={handleOutsideClick}
            className="more-info-popper"
          >
            <div className="doc-info-container">
              <Chip
                key={"lastModified"}
                className="chip-gap"
                icon={<MdOutlineUpdate className="icon-info" />}
                label={moment(state.documentInfo.publishedOn).format(
                  "DD MMM YYYY HH:mm A"
                )}
                variant="outlined"
              />

              <Chip
                key={"user"}
                className="chip-gap"
                label={state.documentInfo.publishedBy.email || ""}
              />
              <Chip
                key={"lastModified"}
                className="chip-gap"
                label={`Version ${state.documentInfo.version}`}
                variant="outlined"
              />
            </div>
          </Popper>
        )}
      </div>
    </div>
  );
};
