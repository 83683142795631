import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Import react Icon
import { AiOutlineRight, AiOutlineStar, AiOutlineLogout } from "react-icons/ai";
import { IoPeopleOutline } from "react-icons/io5";
import { BsTrash3 } from "react-icons/bs";
import { RxDashboard } from "react-icons/rx";
import { VscFeedback } from "react-icons/vsc";

// Internal components & assests
import logo from "../../resources/images/carnival-logo.svg";

// Context
import { GlobalContextData } from "../../context";

// Style
import "./LeftNavBar.scss";
import { BaseModal } from "../BaseModals";
import { HelpBox } from "../HelpBox";
import { getUserData } from "../../shared/utils";
import { useAuthService } from "../../hooks";

export const LeftNavBar = () => {
  const { state, dispatch } = useContext(GlobalContextData);

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  const { logOut } = useAuthService("");
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  const handleSignOut = () => {
    logOut();
  };

  return (
    <nav className={`sidebar ${state.openSidebar ? "" : "close"}`}>
      <header>
        <div className="image-text">
          {state.openSidebar ? (
            <span className="image img-margin hide-logo">
              <img
                src={"/Logo.jpg"}
                alt=" Logo"
                height={64}
                width={74}
                style={{ marginTop: ".5rem", marginLeft: "-1rem" }}
              />
            </span>
          ) : (
            <span className="image">
              <img src="/Logo.jpg" alt=" Logo" height={50} />
            </span>
          )}
          {/* <span className="image mobile-view-logo">
            <img
              src=" https://www.carnival.com/apple-touch-icon-192x192.png"
              alt=" Logo"
              height={44}
            />
          </span> */}
        </div>

        <div
          className="toggle"
          onClick={() =>
            dispatch({
              type: "OPEN_CLOSE_SIDE_BAR",
              payload: !state.openSidebar,
            })
          }
        >
          <AiOutlineRight />
        </div>
      </header>

      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            <li className="nav-link ">
              <Link
                to="/overview"
                className={`left-section-text-color ${
                  pathName === "/overview" ? "selected-link" : ""
                }`}
              >
                <RxDashboard className="icon" />
                <span className="text nav-text">Overview</span>
              </Link>
            </li>
            {userData && userData?.role?.name !== "ship-user" && (
              <li className="nav-link">
                <Link
                  to="/user-management"
                  className={`left-section-text-color ${
                    pathName === "/user-management" ? "selected-link" : ""
                  }`}
                >
                  <IoPeopleOutline className="icon" />
                  <span className="text nav-text">User Management</span>
                </Link>
              </li>
            )}
            <li className="nav-link">
              <Link
                to="/templates"
                className={`left-section-text-color ${
                  pathName === "/templates" ? "selected-link" : ""
                }`}
              >
                <AiOutlineStar className="icon" />
                <span className="text nav-text">Templates</span>
              </Link>
            </li>

            <li className="nav-link">
              <Link
                to="/trash"
                className={`left-section-text-color ${
                  pathName === "/trash" ? "selected-link" : ""
                }`}
              >
                <BsTrash3 className="icon" />
                <span className="text nav-text">Trash</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="bottom-content">
          <li className="nav-link" onClick={() => setShowModal(!showModal)}>
            <Link to="#" className="left-section-text-color">
              <VscFeedback className="icon" />
              <span className="text nav-text">Support</span>
            </Link>
          </li>
          <li className="nav-link" onClick={handleSignOut}>
            <Link to="#" className="left-section-text-color">
              <AiOutlineLogout className="icon" />
              <span className="text nav-text">Sign Out</span>
            </Link>
          </li>
          <BaseModal
            modalTitle="Reach Out for Expert Assistance"
            open={showModal}
            onClose={() => setShowModal(false)}
          >
            <HelpBox onCancel={() => setShowModal(false)} />
          </BaseModal>
        </div>
      </div>
    </nav>
  );
};
