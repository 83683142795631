import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getUserData } from "../../shared/utils";

const protectedAuthRoutes = ["/invite-user", "/reset-password"];

export const AuthLayout = () => {
  const location = useLocation();

  if (
    protectedAuthRoutes.includes(location.pathname) &&
    !getUserData()?.email
  ) {
    return <Navigate to={"/"} />;
  }

  return <Outlet />;
};
