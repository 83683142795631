import { lazy } from "react";

// project imports
import { Loadable, MainLayout } from "../../components";

const DocumentCreatingWizard = Loadable(
  lazy(() =>
    import("../../pages/DocumentCreatingWizard").then((module) => ({
      default: module.DocumentCreatingWizard,
    }))
  )
);

const DocumentPublishWizard = Loadable(
  lazy(() =>
    import("../../pages/DocumentPublishWizard").then((module) => ({
      default: module.DocumentPublishWizard,
    }))
  )
);

const Overview = Loadable(
  lazy(() =>
    import("../../pages/Overview").then((module) => ({
      default: module.Overview,
    }))
  )
);

const EditorView = Loadable(
  lazy(() =>
    import("../../pages/EditorView").then((module) => ({
      default: module.EditorView,
    }))
  )
);

const Preview = Loadable(
  lazy(() =>
    import("../../pages/EditorView/Preview").then((module) => ({
      default: module.Preview,
    }))
  )
);

const TemplateView = Loadable(
  lazy(() =>
    import("../../pages/EditorView/TemplateView").then((module) => ({
      default: module.TemplateView,
    }))
  )
);

const UserManagement = Loadable(
  lazy(() =>
    import("../../pages/UserManagement").then((module) => ({
      default: module.UserManagement,
    }))
  )
);

const Templates = Loadable(
  lazy(() =>
    import("../../pages/Templates").then((module) => ({
      default: module.Templates,
    }))
  )
);

const Trash = Loadable(
  lazy(() =>
    import("../../pages/Trash").then((module) => ({
      default: module.Trash,
    }))
  )
);

const Settings = Loadable(
  lazy(() =>
    import("../../pages/Settings").then((module) => ({
      default: module.Settings,
    }))
  )
);

const PublishView = Loadable(
  lazy(() =>
    import("../../pages/EditorView/PublishView").then((module) => ({
      default: module.PublishView,
    }))
  )
);

// ==============================|| MAIN ROUTING ||============================== //

export const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/overview",
      element: <Overview />,
    },
    {
      path: "/create-document",
      element: <DocumentCreatingWizard />,
    },
    {
      path: "/update-document/:id",
      element: <DocumentCreatingWizard />,
    },
    {
      path: "/publish-document/:id",
      element: <DocumentPublishWizard />,
    },

    {
      path: "/editor-view/:id",
      element: <EditorView />,
    },
    {
      path: "/publish-view/:id",
      element: <PublishView />,
    },
    {
      path: "/shared/:documentId/:sharedDocumentId/:token",
      element: <Preview />,
    },
    {
      path: "/user-management",
      element: <UserManagement />,
    },
    {
      path: "/templates",
      element: <Templates />,
    },
    {
      path: "/templates/:id",
      element: <Templates />,
    },
    {
      path: "/template-view/:id",
      element: <TemplateView />,
    },
    {
      path: "/trash",
      element: <Trash />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
  ],
};
