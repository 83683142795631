import React from "react";
import "./UserProfile.scss";
import { ProfileImageProps } from "./userProfile.type";
export const UserProfile: React.FC<ProfileImageProps> = ({
  firstName,
  lastName,
  email,
  role,
}) => {
  const initials = (email?.charAt(0) || "U").toUpperCase();
  return (
    <div className="user-profile-container">
      <div className="user-profile">
        <div className="profile-image">{initials}</div>
        <div className="header-name-email">
          <div>
            <p className="header-user-name">
              {firstName} {lastName || ""}
            </p>
            <p className="header-user-email">{email}</p>
            <p className="header-role">role: {role}</p>
          </div>
          <div className="manage-bottom-container">
            <button className="manage-account-btn">Manage your Account</button>
          </div>
        </div>
      </div>
    </div>
  );
};
