import { lazy } from "react";
// project imports
import { AuthLayout, Loadable } from "../../components";

const SignIn = Loadable(
  lazy(() =>
    import("../../pages/Auth").then((module) => ({
      default: module.Login,
    }))
  )
);
const ForgetPassword = Loadable(
  lazy(() =>
    import("../../pages/Auth").then((module) => ({
      default: module.ForgetPassword,
    }))
  )
);
const ResetPassword = Loadable(
  lazy(() =>
    import("../../pages/Auth").then((module) => ({
      default: module.ResetPassword,
    }))
  )
);

const NotFoundPage = Loadable(
  lazy(() =>
    import("../../pages/Auth").then((module) => ({
      default: module.NotFoundPage,
    }))
  )
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

export const AuthRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/",
      element: <SignIn />,
    },
    {
      path: "/forgot-password",
      element: <ForgetPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ],
};
