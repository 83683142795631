import React, { useState } from "react";

// Third party
import { Chip } from "@mui/material";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Internal
import "./ShareDocumentModal.scss";
import { toast } from "react-toastify";
import { MdCancel, MdSend } from "react-icons/md";
import { Button } from "../../../components";
import { usePostService } from "../../../hooks";
import { API } from "../../../Constants";

type ShareDocumentModalProps = {
  onSubmit: () => void;
  onClose: () => void;
  id: string;
};

interface Option {
  label: string;
  value: string;
}

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

export const ShareDocumentModal = ({
  onSubmit,
  onClose,
  id,
}: ShareDocumentModalProps) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null>();
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  const [startDate, setStartDate] = useState<Date | null>(tomorrow);

  const isDateDisabled = (date: any) => {
    return date > today;
  };

  const handleCreate = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setOptions((prev: any) => [...prev, newOption]);
    setValue(null);
  };

  const handleDelete = (chipIndex: number) => {
    const data = [...options];
    data.splice(chipIndex, 1);
    setOptions(data);
  };

  const { postData } = usePostService();

  const handleSubmit = async () => {
    const recipientEmails = options.map((option) => option.label);

    const payload = {
      documentId: id,
      expiryDate: startDate?.toISOString(),
      recipientEmails,
    };

    try {
      const response = await postData(
        `${API.DOCUMENT.SHARE_DOC_CALL}`,
        payload
      );

      if (response) {
        onSubmit();
        toast.info("Document shared successfully!");
      }
    } catch (error) {
      toast.error("An error occurred while sharing the document.");
    }
  };
  return (
    <div className="share-document-modal">
      <div className="help-box-body">
        <p className="help-box-subheading">
          Please add email & share with team members.
        </p>
        <div className="input-fields">
          <div className="padd-top">
            <label className="label-style">Add email</label>
            <CreatableSelect
              isClearable
              onChange={(newValue) => setValue(newValue)}
              onCreateOption={handleCreate}
              options={options}
              value={value}
              placeholder={<p>Type to search & Add Email</p>}
            />
          </div>
          <div className="padd-top">
            <label className="label-style">Emails</label>
            <div className="tag-container">
              {options &&
                options.length > 0 &&
                options.map((tag, index: number) => {
                  return (
                    <Chip
                      key={index}
                      className="chip-gap"
                      label={tag.label}
                      variant="outlined"
                      onDelete={() => handleDelete(index)}
                    />
                  );
                })}
            </div>
            <div className="container-calender">
              <label className="label-style">Expiry date</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date as Date)}
                filterDate={isDateDisabled}
                locale="en"
                className="calender"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="help-box-bottom">
        <Button className="help-box-cancel" onClick={onClose}>
          <MdCancel className="cancel-icon" />
          Cancel
        </Button>
        <Button
          className="help-box-submit"
          onClick={handleSubmit}
          disabled={!id || options.length === 0}
        >
          <MdSend className="submit-icon" />
          Share
        </Button>
      </div>
    </div>
  );
};
