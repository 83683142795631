import { useRoutes } from "react-router-dom";
import { useLogoutTimer } from "../../hooks";

// routes
import { AuthRoutes, MainRoutes } from "../index";

export const ThemeRoutes = () => {
  useLogoutTimer(90);
  return useRoutes([AuthRoutes, MainRoutes]);
};
