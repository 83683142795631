import React, { useEffect, useState } from "react";
import "./HelpBox.scss";
import { Button } from "../Button";
import { MdCancel, MdSend } from "react-icons/md";
import { HelpBoxProps } from "./help-box.types";
import { usePostService } from "../../hooks";
import { toast } from "react-toastify";
import { API } from "../../Constants";

export const HelpBox: React.FC<HelpBoxProps> = ({ onCancel }) => {
  const [text, setText] = useState("");

  const { postData } = usePostService();

  useEffect(() => {
    setText("");
  }, []);

  const handleSubmit = async () => {
    if (!text) {
      return toast.error("Please enter a message");
    }

    try {
      const res = await postData(API.SUPPORT.CONTACT_US, { message: text });
      if (res.data === "Email sent successfully") {
        onCancel();
        return toast.info("Message sent successfully");
      }

      toast.error("Failed to send message. Please try again later.");
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
    }
  };

  const handleClose = () => {
    onCancel();
  };
  return (
    <div className="help-box">
      <div className="help-box-body">
        <p className="help-box-subheading">
          Got a problem or need assistance? Our dedicated team is ready to help!
          Simply provide a brief description of the issue you're facing, and
          we'll connect with you shortly to provide the assistance you need. We
          value your time and are committed to resolving any challenges you
          encounter. Count on us for reliable support and a smooth experience.
          Your satisfaction is our priority!
        </p>
        <textarea
          className="help-box-textarea"
          placeholder="Enter your message..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          rows={8}
        />
      </div>
      <hr />
      <div className="help-box-bottom">
        <Button className="help-box-cancel" onClick={handleClose}>
          <MdCancel className="cancel-icon" />
          Cancel
        </Button>
        <Button
          className="help-box-submit"
          onClick={handleSubmit}
          disabled={text === ""}
        >
          <MdSend className="submit-icon" />
          Submit
        </Button>
      </div>
    </div>
  );
};
