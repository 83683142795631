import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Third party
import { HiPlusSm } from "react-icons/hi";
// Internal components
import "./TopHeader.scss";
import { Button, Popper, BaseModal } from "../";
import {
  DraftTopHeader,
  PublishTopHeader,
  UserProfile,
  SharedTopHeader,
  TemplateTopHeader,
} from "./";

import { getUserData } from "../../shared/utils";
import { GlobalContextData } from "../../context";
import { DOC_STATUS } from "../../Constants";

// Share doc modal
import { ShareDocumentModal } from "../../pages/EditorView/ShareDocumentModal";
import { DeletedTopHeader } from "./DeletedTopHeader";

export const TopHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  const shouldShowSharedHeader = location.pathname.includes("/shared");

  const { state, dispatch } = useContext(GlobalContextData);

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (!target.closest(".user-popper")) {
      setShowDropdown(false);
    }
  };

  const shouldShowCreateDocButton = location.pathname.includes("/overview");

  const shouldShowTemplateTopheader =
    location.pathname.includes("/templates") ||
    location.pathname.includes("/template-view");

  const handleUser = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const userFirstName = userData?.firstName || "User";
  const initials = (userData?.email?.charAt(0) || "U").toUpperCase();
  return (
    <>
      <header className="top-header">
        {!shouldShowSharedHeader &&
          !shouldShowTemplateTopheader &&
          state.documentInfo.status === "" && (
            <>
              <div>
                <h1>SARWeb</h1>
                <div>
                  {/* <button className="header-opt-btn">All</button>
                <button className="header-opt-btn">Shared</button>
                <button className="header-opt-btn">File Request</button> */}
                </div>
              </div>
              <div className="header-right">
                {shouldShowCreateDocButton && (
                  <div>
                    <Button
                      className="header-doc-btn"
                      onClick={() => {
                        dispatch({
                          type: "SHOW_AND_HIDE_STEPPER",
                          payload: false,
                        });
                        navigate("/create-document");
                      }}
                    >
                      <HiPlusSm className="header-plus-icon" />
                      <span className="header-new-btn">New document</span>
                    </Button>
                  </div>
                )}

                <div className="header-email">
                  <div className="header-icon-name">
                    <div>
                      <div
                        className="profile-image"
                        onClick={() => setShowDropdown(true)}
                      >
                        {initials}
                      </div>
                      {showDropdown && (
                        <Popper
                          onClose={handleUser}
                          handleClickOutside={handleOutsideClick}
                          className="user-popper"
                        >
                          <UserProfile
                            firstName={userFirstName}
                            lastName={userData?.lastName}
                            email={userData?.email}
                            role={userData?.role.name}
                          />
                        </Popper>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

        {state.documentInfo.status === DOC_STATUS.DRAFT &&
          state.documentInfo.deleted === false && <DraftTopHeader />}

        {state.documentInfo.status === DOC_STATUS.PUBLISH &&
          state.documentInfo.deleted === false &&
          !shouldShowSharedHeader && (
            <PublishTopHeader onClick={() => setShowShareModal(true)} />
          )}

        {shouldShowTemplateTopheader && <TemplateTopHeader />}

        {shouldShowSharedHeader && <SharedTopHeader />}
        {state.documentInfo.deleted === true && <DeletedTopHeader />}
      </header>

      {showShareModal && (
        <BaseModal
          open={showShareModal}
          onClose={() => setShowShareModal(false)}
          modalTitle="Please share the document"
          children={
            <ShareDocumentModal
              id={state.documentInfo.id}
              onSubmit={() => setShowShareModal(false)}
              onClose={() => setShowShareModal(false)}
            />
          }
        />
      )}
    </>
  );
};
