import { Skeleton } from "@mui/material";

export const RecentUpdatePlaceholder = () => {
  return (
    <>
      <div>
        <Skeleton variant="rectangular" width={230} height={60} />
      </div>
      <div>
        <Skeleton variant="rectangular" width={230} height={60} />
      </div>
      <div>
        <Skeleton variant="rectangular" width={230} height={60} />
      </div>
      <div>
        <Skeleton variant="rectangular" width={230} height={60} />
      </div>
      <div>
        <Skeleton variant="rectangular" width={230} height={60} />
      </div>
      <div>
        <Skeleton variant="rectangular" width={230} height={60} />
      </div>
    </>
  );
};
