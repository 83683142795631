import { useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import axiosInstance from "../shared/axiosInstance";

export const usePostService = () => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  const [isLoading, setIsLoading] = useState(false);

  const postData = async (url: string, payload: any, method?: string) => {
    setIsLoading(true);
    try {
      const result = await axiosInstance.request({
        method: method ? method : "POST",
        url: process.env.REACT_APP_BASE_URL + "/" + url,
        data: payload,
      });
      setResponse(result.data);
      return result;
    } catch (err: any) {
      setError(err?.message);
      setIsLoading(false);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { response, error, isLoading, postData };
};
