// Third party
import { Alert, AlertTitle } from "@mui/material";

// Internal imports
import "./RecentUpdate.scss";
import { NotificationItem } from "../NotificationItem";
import { IRecentUpdateProps } from "./RecentUpdate.types";
import { formatDateToCustomFormat, formatVersion } from "../../shared/utils";
import { RecentUpdatePlaceholder } from "./RecentUpdatePlaceholder";
import { useState } from "react";

export const RecentUpdate = ({
  documents,
  error,
  isLoading,
}: IRecentUpdateProps) => {
  // const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(5);
  const visibleDocuments = documents.slice(0, currentPage);
  const allDocumentsDisplayed = visibleDocuments.length === documents.length;
  const handleViewMore = () => {
    setCurrentPage(currentPage + 5);
  };
  return (
    <div className="recent-update-container">
      <div className="recent-update-heading">
        <p className="recent-heading">Recently Updated</p>
        {!allDocumentsDisplayed && (
          <p className="recent-view-more" onClick={handleViewMore}>
            View more
          </p>
        )}
      </div>
      {!isLoading && error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Some thing went wrong. Please reload once. -{" "}
          <strong>{error as unknown as string}!</strong>
        </Alert>
      )}
      {}
      <div className="recent-update-container__list">
        {isLoading && <RecentUpdatePlaceholder />}
        {!isLoading &&
          !error &&
          visibleDocuments.map((document) => (
            <NotificationItem
              name={`${document.name} (${formatVersion(document.version || 1.0)})`}
              key={document.id}
              date={`${document.shipName} - ${formatDateToCustomFormat(
                document.lastModified
              )}`}
            />
          ))}
      </div>
    </div>
  );
};
