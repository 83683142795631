//import { Link } from "react-router-dom";

//Third party
import { FcDocument } from "react-icons/fc";
import { GiShipWheel } from "react-icons/gi";

// Internal
import { useContext } from "react";
import { CustomStepper, DisplayStatus } from "../../../components";
import { GlobalContextData } from "../../../context";
import "./DraftTopHeader.scss";
import { Chip } from "@mui/material";
import { DOC_STATUS } from "../../../Constants";

export const DraftTopHeader = () => {
  const { state } = useContext(GlobalContextData);
  return (
    <div className="draft-Top-Header ">
      {/* <Link className="back-to-overview" to={"/overview"}>
        <ArrowBackIosIcon className="icon-back" />
        <div>Back</div>
      </Link> */}
      <div className="left-draft-section">
        <span>
          <FcDocument className="fc-doc" />
        </span>
        <div className="doc-with-status">
          <span className="doc-name">{state.documentInfo.name || ""}</span>
          {state.documentInfo.name !== "" &&
            state.documentInfo.status === DOC_STATUS.DRAFT && (
              <DisplayStatus status={"Draft"} />
            )}
        </div>
      </div>

      <div className="center-draft-section ">
        <CustomStepper />
      </div>
      {state.documentInfo.shipName && (
        <div className="right-draft-section">
          <Chip
            key={"lastModified"}
            className="chip-gap"
            icon={<GiShipWheel className="ship-icon " />}
            label={state.documentInfo.shipName || ""}
            variant="outlined"
          />
        </div>
      )}
    </div>
  );
};
