import React from "react";
import "./NotificationItem.scss";
import { NotificationItemProps } from "./notification-item.type";
export const NotificationItem: React.FC<NotificationItemProps> = ({
  name,
  date,
}) => {
  return (
    <div className="popper-content">
      <div className="notebook-icon-container">
        <svg
          className="notebook-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4 6C4 4.34315 5.34315 3 7 3H17C18.6569 3 20 4.34315 20 6V18C20 19.6569 18.6569 21 17 21H7C5.34315 21 4 19.6569 4 18V6Z"
            stroke="#0D0138"
            strokeWidth="1.5"
          />
          <path
            d="M16 4C16 3.44772 16.4477 3 17 3V3C18.6569 3 20 4.34315 20 6V7C20 7.55228 19.5523 8 19 8H17C16.4477 8 16 7.55228 16 7V4Z"
            stroke="#0D0138"
            strokeWidth="1.5"
          />
          <path
            d="M8 11H16"
            stroke="#0D0138"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 14H16"
            stroke="#0D0138"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="notification-container">
        <p className="notification-name">{name}</p>
        <p className="notification-date">{date}</p>
      </div>
      <div className="notification-right-icon">
        <svg
          className="notification-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="4"
          height="20"
          viewBox="0 0 4 20"
          fill="none"
        >
          <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          <circle cx="2" cy="10" r="2" fill="#F3F3F3" />
          <circle cx="2" cy="18" r="2" fill="#F3F3F3" />
        </svg>
      </div>
    </div>
  );
};
