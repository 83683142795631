import { useState, useEffect } from "react";
import { AxiosError } from "axios";
import axiosInstance from "../shared/axiosInstance";

export const useGetService = (url?: string, sharedToken?: string) => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<AxiosError>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const result = await axiosInstance.get(
        process.env.REACT_APP_BASE_URL + "/" + url
      );
      setResponse(result);
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    url && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  // Refetch data
  const refetch = async (updatedURL: string) => {
    setIsLoading(true);
    try {
      const result = await axiosInstance.get(
        process.env.REACT_APP_BASE_URL + "/" + updatedURL
      );
      setResponse(result);
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { response, error, isLoading, refetch };
};
