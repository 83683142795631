import { ReactNode, useState } from "react";

// Third party
import { AssignmentLate } from "@mui/icons-material";
import { IoIosArrowDown } from "react-icons/io";

// Internal
import "./Grid.scss";
import { SkeltonPlaceholder } from "./SkeltonPlaceholder";

interface GridProps {
  columns: columnType[];
  rows: ReactNode[] | JSX.Element[];
  isLoading: boolean;
  handleIconClick?: (item: any) => void;
  enableSorting?: boolean;
  sortableKey?: Array<string>;
}

interface columnType {
  label: string | React.ReactNode;
  id?: number;
  name: string;
}

const TableDisplay = ({ item, column, handleIconClick }: any) => {
  return (
    // @ts-ignore: Unreachable code error
    <td
      data-label={column.name}
      onClick={() =>
        handleIconClick && handleIconClick(item, item[column.name])
      }
    >
      {column.render ? column.render(item) : item[column.name] || "--"}
    </td>
  );
};

export const Grid = ({
  columns,
  rows,
  isLoading,
  handleIconClick,
  enableSorting = false,
  sortableKey,
}: GridProps) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = (columnName: string) => {
    if (!enableSorting) return;
    if (sortColumn === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };
  const prepareRows = (
    rowsData: ReactNode[] | JSX.Element[],
    columns: columnType[]
  ) => {
    const sortData = sortColumn
      ? rowsData.sort((a, b) => {
          const aValue = (a as any)[sortColumn];
          const bValue = (b as any)[sortColumn];
          if (typeof aValue === "string" && typeof bValue === "string") {
            return sortOrder === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          } else {
            return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
          }
        })
      : rowsData || [];
    return sortData?.map((item, index) => (
      <tr key={index}>
        {columns?.map((column, columnIndex) => (
          <TableDisplay
            item={item}
            column={column}
            key={columnIndex}
            handleIconClick={handleIconClick}
          />
        ))}
      </tr>
    ));
  };

  return (
    <div className="custom-grid-container">
      <table>
        <thead>
          <tr>
            {columns.map((column, index) => {
              return (
                <th
                  key={index}
                  scope="col"
                  onClick={() => handleSort(column.name)}
                >
                  {column.label}
                  {column.label !== "" &&
                    typeof column.label === "string" &&
                    enableSorting &&
                    sortableKey &&
                    sortableKey?.length > 0 &&
                    sortableKey.includes(column.label) && (
                      <IoIosArrowDown
                        className={`sort-icon ${
                          sortColumn === column.name
                            ? sortOrder === "asc"
                              ? "rotate180"
                              : "rotate"
                            : ""
                        }`}
                      />
                    )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!isLoading && rows && rows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <AssignmentLate color="info" fontSize="large" />
                <p className="data-text">No Data Found</p>
              </td>
            </tr>
          )}
          {isLoading ? <SkeltonPlaceholder /> : prepareRows(rows, columns)}
        </tbody>
      </table>
    </div>
  );
};
