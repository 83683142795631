import { useState } from "react";

// Third party
import { AxiosError } from "axios";
import html2canvas from "html2canvas";
import { usePostService } from "./usePostService";

export const useScreenshot = () => {
  const [response] = useState<any>();
  const [error] = useState<AxiosError>();
  const [isLoading] = useState(true);
  const { postData } = usePostService();

  const input = document.getElementById("editor-container");
  const temp = input?.getElementsByClassName("editor")[0];

  // const a = document.getElementById("contentEditable__root")?.childNodes[0];

  const takeScreenshot = (url?: string, id?: string) => {
    const cloneNode: any = temp?.cloneNode(true);
    cloneNode?.setAttribute(
      "style",
      "height:100vh; overflow:hidden; position:absolute;left:-200%;width:60%"
    );
    document.body.appendChild(cloneNode);
    html2canvas(cloneNode as HTMLElement).then(async function (canvas) {
      var dataURL = canvas.toDataURL();
      if (canvas && id && url) {
        try {
          const response = await postData(url, {
            folderName: id,
            fileName: "snapshot",
            base64Image: dataURL,
            invalidateCDNCache: true,
          });
          console.log("Image uploaded successfully!", response.data.imageUrl);
        } catch (error) {
          console.error("Error uploading image:", error);
        } finally {
          document.body.removeChild(cloneNode);
        }
      }
    });
  };

  return { takeScreenshot, response, error, isLoading };
};
