import { BrowserRouter as Router } from "react-router-dom";

//Third party
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Internal imports
import { ThemeRoutes } from "./routes";

//Style
import "./App.scss";
import { GlobalContext } from "./context";

function App() {
  return (
    <GlobalContext>
      <div className="app-container">
        <ToastContainer />
        <Router>
          <ThemeRoutes />
        </Router>
      </div>
    </GlobalContext>
  );
}

export default App;
